import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import Logo from "../../assets/appImages/app-logo.png";
import { Devis, ModelDevis, UserInfos } from "../../redux/types";
import { devisListner } from "../../utils/api/user.api";
import "./guest.css";
import { getLastModel, onRejectDevis, onSignDevis } from "./hooks";
const ShowDevis = () => {
  const [doc, setDoc] = useState<{
    devis: Devis;
    model: ModelDevis;
    user: UserInfos;
  }>();
  const ref = useRef<any>(null);
  const params = useParams<{ id: string }>();

  const getData = (id: string) => {
    setDoc(undefined);
    devisListner(id).then((res) => {
      if (res) {
        setDoc({
          devis: res.devis,
          model: getLastModel(res.models),
          user: res.user,
        });
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, []);
  const isSent = doc?.devis?.statut === "Envoyé";
  // const isSent = true;
  return (
    <div>
      <div className="text-center bg-primary py-3">
        <img src={Logo} alt="Logo" className="logo-app" />
      </div>
      {!doc && (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
            role="status"
          ></span>
          <span>Chargements</span>
        </div>
      )}
      {!!doc && (
        <div className="container">
          <div className="row mt-2">
            <div className="col-md-4"></div>

            <div className="col-md-4 col-sm-12 my-2">
              <div className="row">
                <div className="col-md-12 col-sm-12 my-2">
                  <p className="my-2">
                    Télécharger le devis{" "}
                    {doc.devis.statut === "Signé" ? "signé" : ""}
                  </p>
                  <button
                    className="btn w-100 text-white bg-primary"
                    type="submit"
                    onClick={() => {
                      window.open(doc.devis.file, "_blank");
                    }}
                  >
                    Télécharger
                  </button>
                </div>
                {isSent && (
                  <>
                    <div className="col-md-12">
                      <p className="my-2">Signature électronique</p>
                      <SignatureCanvas
                        ref={ref}
                        onEnd={(props) => {
                          console.log("props", props);
                        }}
                        penColor="black"
                        canvasProps={{ className: "sigCanvas" }}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <button
                        className="btn btn-outline-primary w-100"
                        type="submit"
                        onClick={(e) => {
                          ref.current.clear();
                        }}
                      >
                        Recommencer
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <button
                        className="btn btn-primary w-100"
                        type="submit"
                        onClick={() => {
                          const base64 = ref.current
                            .getTrimmedCanvas()
                            .toDataURL("image/png");
                          if (base64) {
                            onSignDevis(
                              doc.devis,
                              doc.model,
                              doc.user,
                              base64,
                              () => getData(doc.devis.id)
                            );
                          }
                        }}
                      >
                        Accepter
                      </button>
                    </div>
                    <div className="col-md-12 col-sm-12 mt-2">
                      <p className="my-2">
                        Refuser le devis et notifier le prestataire
                      </p>
                      <button
                        className="btn w-100 text-white bg-danger"
                        type="submit"
                        onClick={() => {
                          onRejectDevis(doc.devis.id, () =>
                            getData(doc.devis.id)
                          );
                        }}
                      >
                        Refuser
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowDevis;
